import { subDays, subHours } from 'date-fns'

import { mock } from '../lib/axiosMock'
import type { Recruiter } from '../types/recruiter'

const now = new Date()

mock.onGet('/api/recruiters').reply(() => {
  const recruiters: Recruiter[] = [
    {
      id: '5e887ac47eed253091be10cb',
      email: 'fran.perez@devias.io',
      isEmailVerified: true,
      name: '山田 一郎',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:34:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      email: 'fran.perez@devias.io',
      isEmailVerified: true,
      name: '鈴木 二郎',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887b7602bdbc4dbb234b27',
      email: 'jie.yan.song@devias.io',
      isEmailVerified: true,
      name: '太田 三郎',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e86809283e28b96d2d38537',
      email: 'jane.rotanson@devias.io',
      isEmailVerified: true,
      name: '前田 四郎',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e86805e2bafd54f66cc95c3',
      email: 'miron.vitold@devias.io',
      isEmailVerified: true,
      name: '小島 花子',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887a1fbefd7938eea9c981',
      email: 'penjani.inyene@devias.io',
      isEmailVerified: true,
      name: '村田 智子',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887d0b3d090c1b8f162003',
      email: 'omar.darobe@devias.io',
      isEmailVerified: true,
      name: '久 智田',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e88792be2d4cfb4bf0971d9',
      email: 'siegbert.gottfried@devias.io',
      isEmailVerified: true,
      name: '大 享島',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e8877da9a65442b11551975',
      email: 'iulia.albu@devias.io',
      isEmailVerified: true,
      name: '川 実口',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e8680e60cba5019c5ca6fda',
      email: 'nasimiyu.danai@devias.io',
      isEmailVerified: true,
      name: '飯田 雅仁',
      phoneNumberStatus: 'unregistered',
      roleType: 'recruiter_admin',
      agencyCode: '200999',
      agencyName: 'オリーブ少額短期保険株式会社',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
  ]

  return [200, { recruiters }]
})

mock.onGet('/api/recruiters/1').reply(() => {
  const recruiter: Recruiter = {
    id: '5e887ac47eed253091be10cb3',
    email: 'fran.perez@devias.io',
    isEmailVerified: true,
    name: '山田 一郎',
    phoneNumberStatus: 'unregistered',
    roleType: 'recruiter_admin',
    agencyCode: 'オリーブ少額短期保険株式会社',
    agencyName: '200999',
    lastLoginAt: '2021/04/14 11:34:33',
    isMfaActive: false,
    createdAt: subDays(subHours(now, 7), 1).getTime(),
    updatedAt: subDays(subHours(now, 7), 1).getTime(),
  }

  return [200, { recruiter }]
})
