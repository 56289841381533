import { OliveUser } from './oliveUser'
import { Recruiter } from './recruiter'

export type User = OliveUser | Recruiter

// ログイン中のユーザーの種類
export const LOGIN_USER_STATUS = {
  OLIVE_SUPER_ADMIN_SELECTED_AGENCY: 'olive_super_admin_selected_agency',
  OLIVE_ADMIN_SELECTED_AGENCY: 'olive_admin_selected_agency',
  OLIVE_GENERAL_SELECTED_AGENCY: 'olive_general_selected_agency',
  OLIVE_SUPER_ADMIN: 'olive_super_admin',
  OLIVE_ADMIN: 'olive_admin',
  OLIVE_GENERAL: 'olive_general',
  RECRUITER_ADMIN: 'recruiter_admin',
  RECRUITER_GENERAL: 'recruiter_general',
} as const

export type LoginUserStatus =
  (typeof LOGIN_USER_STATUS)[keyof typeof LOGIN_USER_STATUS]
