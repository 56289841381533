import { createOliveUser, OliveUserCreateRequestBody } from './createOliveUser'
import { destroyOliveUser } from './destroyOliveUser'
import { fetchOliveUser } from './fetchOliveUser'
import { fetchOliveUsers } from './fetchOliveUsers'
import { OliveUserUpdateRequestBody, updateOliveUser } from './updateOliveUser'

export const oliveUsers = () => {
  return {
    index() {
      return fetchOliveUsers()
    },
    show(id: string) {
      return fetchOliveUser(id)
    },
    post(requestBody: OliveUserCreateRequestBody) {
      return createOliveUser(requestBody)
    },
    put(id: string, requestBody: OliveUserUpdateRequestBody) {
      return updateOliveUser(id, requestBody)
    },
    destroy(id: string) {
      return destroyOliveUser(id)
    },
  }
}
