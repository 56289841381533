import { subDays, subHours } from 'date-fns'
import type { Agency } from 'src/types/agency'

import { mock } from '../lib/axiosMock'

const now = new Date()

mock.onGet('/api/agencies').reply(() => {
  const agencies: Agency[] = [
    {
      agencyCode: '200999',
      name: '株式会社ニュートン・フィナンシャル・コンサルティング',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200173',
      name: 'オリーブ少額短期保険株式会社',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200998',
      name: '株式会社ライフィ',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200997',
      name: '株式会社ミライトデザイン',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200995',
      name: '株式会社ニュートン・フィナンシャル・コンサルティング2',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200994',
      name: 'オリーブ少額短期保険株式会社2',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200993',
      name: '株式会社ライフィ2',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '200992',
      name: '株式会社ミライトデザイン1',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '12345',
      name: '株式会社ニュートン・フィナンシャル・コンサルティング',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '12345',
      name: 'オリーブ少額短期保険株式会社',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '12345',
      name: '株式会社ライフィ',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      agencyCode: '12345',
      name: '株式会社ミライトデザイン',
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
  ]

  return [200, { agencies }]
})
