import { AxiosResponse } from 'axios'
import { OliveUser } from 'src/types/oliveUser'

import axios from '../../axios'

export const fetchOliveUsers = async (): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: OliveUser[] }>('/api/olive-users')
    .catch((error) => {
      return error.response
    })

  return response
}
