import {
  OLIVE_ADMIN,
  OLIVE_GENERAL,
  OLIVE_SUPER_ADMIN,
  RECRUITER_ADMIN,
} from 'src/types/roleType'
import { LOGIN_USER_STATUS, LoginUserStatus } from 'src/types/user'

import { useAuth } from './useAuth'

const useLoginUserStatus = (): LoginUserStatus | undefined => {
  const { user } = useAuth()
  if (!user) {
    return
  }

  const userRole = user.roleType

  if (userRole === OLIVE_SUPER_ADMIN) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.OLIVE_SUPER_ADMIN_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.OLIVE_SUPER_ADMIN
    }
  }

  if (userRole === OLIVE_ADMIN) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.OLIVE_ADMIN_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.OLIVE_ADMIN
    }
  }

  if (userRole === OLIVE_GENERAL) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.OLIVE_GENERAL_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.OLIVE_GENERAL
    }
  }

  if (userRole === RECRUITER_ADMIN) {
    return LOGIN_USER_STATUS.RECRUITER_ADMIN
  } else {
    return LOGIN_USER_STATUS.RECRUITER_GENERAL
  }
}

export default useLoginUserStatus
