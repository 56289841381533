import { AxiosResponse } from 'axios'
import { OliveUser } from 'src/types/oliveUser'
import { OliveUserRoleType } from 'src/types/roleType'

import axios from '../../axios'

export type OliveUserCreateRequestBody = {
  name: string
  email: string
  phoneNumber?: string
  roleType: OliveUserRoleType
}

export const createOliveUser = async (
  requestBody: OliveUserCreateRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: OliveUser }>(`/api/olive-users`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
