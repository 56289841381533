import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OliveUser } from 'src/types/oliveUser'

export type OliveUsersState = {
  oliveUsers: OliveUser[]
}

export const initialOliveUsersState: OliveUsersState = {
  oliveUsers: [],
}

export const oliveUsersSlice = createSlice({
  name: 'oliveUsers',
  initialState: initialOliveUsersState,
  reducers: {
    setOliveUsers: (
      state: OliveUsersState,
      action: PayloadAction<OliveUser[]>
    ) => ({
      ...state,
      oliveUsers: action.payload,
    }),
    updateOliveUser: (
      state: OliveUsersState,
      action: PayloadAction<OliveUser>
    ) => ({
      ...state,
      oliveUsers: state.oliveUsers.map((oliveUser) =>
        oliveUser.id === action.payload.id ? action.payload : oliveUser
      ),
    }),
    createOliveUser: (
      state: OliveUsersState,
      action: PayloadAction<OliveUser>
    ) => {
      state.oliveUsers.push(action.payload)
    },
    deleteOliveUser: (
      state: OliveUsersState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      oliveUsers: state.oliveUsers.filter(
        (oliveUser) => oliveUser.id !== action.payload
      ),
    }),
  },
})

export const {
  setOliveUsers,
  updateOliveUser,
  createOliveUser,
  deleteOliveUser,
} = oliveUsersSlice.actions
