export const OLIVE_SUPER_ADMIN = 'olive_super_admin'
export const OLIVE_ADMIN = 'olive_admin'
export const OLIVE_GENERAL = 'olive_general'
export const RECRUITER_ADMIN = 'recruiter_admin'
export const RECRUITER_GENERAL = 'recruiter_general'

export type OliveUserRoleType =
  | typeof OLIVE_SUPER_ADMIN
  | typeof OLIVE_ADMIN
  | typeof OLIVE_GENERAL
export type RecruiterRoleType =
  | typeof RECRUITER_ADMIN
  | typeof RECRUITER_GENERAL
export type RoleType = OliveUserRoleType | RecruiterRoleType

export const roleTypes = {
  [OLIVE_GENERAL]: 'オリーブ一般',
  [OLIVE_ADMIN]: 'オリーブ管理者',
  [OLIVE_SUPER_ADMIN]: 'オリーブ特権管理者',
  [RECRUITER_GENERAL]: '募集人一般',
  [RECRUITER_ADMIN]: '募集人管理者',
} as const

export type RoleLabel = (typeof roleTypes)[keyof typeof roleTypes]

type RoleTypeLabel = {
  label: RoleLabel
  value: RoleType
}

type OliveRoleTypeLabel = {
  label: string
  value: OliveUserRoleType
}

type RecruiterRoleTypeLabel = {
  label: string
  value: RecruiterRoleType
}

export const roleTypeLabel: RoleTypeLabel[] = [
  {
    label: 'オリーブ一般',
    value: OLIVE_GENERAL,
  },
  {
    label: 'オリーブ管理者',
    value: OLIVE_ADMIN,
  },
  {
    label: 'オリーブ特権管理者',
    value: OLIVE_SUPER_ADMIN,
  },
  {
    label: '募集人一般',
    value: RECRUITER_GENERAL,
  },
  {
    label: '募集人管理者',
    value: RECRUITER_ADMIN,
  },
]

export const oliveRoleTypeLabel: OliveRoleTypeLabel[] = [
  {
    label: '一般',
    value: OLIVE_GENERAL,
  },
  {
    label: '管理者',
    value: OLIVE_ADMIN,
  },
  {
    label: '特権管理者',
    value: OLIVE_SUPER_ADMIN,
  },
]

export const recruiterRoleTypeLabel: RecruiterRoleTypeLabel[] = [
  {
    label: '一般',
    value: RECRUITER_GENERAL,
  },
  {
    label: '管理者',
    value: RECRUITER_ADMIN,
  },
]

export const isOliveUserRole = (
  roleType: RoleType
): roleType is OliveUserRoleType => {
  return (
    roleType === OLIVE_SUPER_ADMIN ||
    roleType === OLIVE_ADMIN ||
    roleType === OLIVE_GENERAL
  )
}
