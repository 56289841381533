import { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { CombinedState } from 'redux'
import { createApiClient } from 'src/lib/api'
import { useDispatch, useSelector } from 'src/store'
import {
  AuthState,
  changeInitialPasswordAction,
  initialize,
  loginAction,
  logoutAction,
  smsAuthenticationAction,
  successfullyVerifiedAction,
  updateAgencyCodeAction,
} from 'src/store/slices/auth'
import {
  fetchFailure,
  fetchStart,
  fetchSuccess,
} from 'src/store/slices/loading'
import { User } from 'src/types/user'
import { needPhoneNumberVerification } from 'src/utils/user'

export const useAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const apiClient = createApiClient()
  const authState = useSelector(
    (state: CombinedState<{ auth: AuthState }>) => state.auth
  )

  const login = async (email: string, password: string): Promise<void> => {
    dispatch(fetchStart())

    const response = await apiClient.auth.login(email, password)

    if (response.status === 200) {
      if (response.data.user) {
        dispatch(fetchSuccess())

        const user = response.data.user as User
        const isEmailVerified = user.isEmailVerified
        const isPhoneNumberVerified = !needPhoneNumberVerification(user)

        if (isEmailVerified && isPhoneNumberVerified) {
          dispatch(loginAction(user))
        } else {
          dispatch(
            initialize({
              isAuthenticated: false,
              user,
            })
          )

          // メールアドレスと電話番号の検証が必要（emailの検証が優先）
          if (!user.isEmailVerified) {
            navigate('/authentication/verify-email')
            return
          }
          if (!isPhoneNumberVerified) {
            navigate('/authentication/verify-phone-number')
          }
        }
      } else {
        dispatch(fetchFailure({ errorMessage: 'ログインに失敗しました。' }))
      }
    } else {
      dispatch(fetchFailure({ errorMessage: response.data.message }))
      if (response.data.code === 'SMS_MFA') {
        navigate('/authentication/sms-authentication', {
          state: {
            session: response.data.data.session,
            email: response.data.data.email,
          },
        })
      }
      if (response.data.code === 'NEW_PASSWORD_REQUIRED') {
        navigate('/authentication/change-initial-password', {
          state: {
            session: response.data.data.session,
            email: response.data.data.email,
          },
        })
      }
    }
  }

  const logout = async (): Promise<void> => {
    dispatch(fetchStart())

    const response = await apiClient.auth.logout()

    if (response.status === 200) {
      dispatch(fetchSuccess())
      dispatch(logoutAction())
      // オリーブユーザーの場合は代理店コードをsession storageに保存しているため削除する
      sessionStorage.clear()
      navigate('/authentication/login')
    } else {
      dispatch(fetchFailure({ errorMessage: 'ログアウトに失敗しました。' }))
    }
  }

  const smsAuthentication = async (
    email: string,
    smsVerificationCode: string,
    session: string
  ): Promise<AxiosResponse> => {
    dispatch(fetchStart())

    const response = await apiClient.auth.smsAuthentication(
      email,
      smsVerificationCode,
      session
    )

    if (response.status === 200) {
      if (response.data.user) {
        dispatch(fetchSuccess())
        const user = response.data.user
        dispatch(smsAuthenticationAction(user))
      } else {
        dispatch(fetchFailure({ errorMessage: 'ログインに失敗しました。' }))
      }
    } else {
      dispatch(fetchFailure({ errorMessage: response.data.message }))
    }
    return response
  }

  const changeInitialPassword = async (
    email: string,
    password: string,
    session: string
  ): Promise<void> => {
    dispatch(fetchStart())

    const response = await apiClient.auth.changeInitialPassword(
      email,
      password,
      session
    )

    if (response.status !== 200) {
      dispatch(fetchFailure({ errorMessage: response.data.message }))
      return
    }

    if (response.data.user) {
      dispatch(fetchSuccess())

      const user = response.data.user as User
      const isEmailVerified = user.isEmailVerified
      const isPhoneNumberVerified = !needPhoneNumberVerification(user)

      if (isEmailVerified && isPhoneNumberVerified) {
        dispatch(changeInitialPasswordAction(user))
      } else {
        dispatch(
          initialize({
            isAuthenticated: false,
            user,
          })
        )

        // メールアドレスと電話番号の検証が必要（emailの検証が優先）
        if (!isEmailVerified) {
          navigate('/authentication/verify-email')
          return
        }
        if (!isPhoneNumberVerified) {
          navigate('/authentication/verify-phone-number')
        }
      }
    } else {
      dispatch(fetchFailure({ errorMessage: 'ログインに失敗しました。' }))
    }
  }

  const successfullyVerified = (user: User) => {
    dispatch(successfullyVerifiedAction(user))
  }

  const updateAgencyCode = (agencyCode?: string) => {
    // オリーブユーザーの場合は代理店選択時にリロードしても選択状態を保持したいため、代理店コードをsession storageで管理している
    if (agencyCode) {
      sessionStorage.setItem('agencyCode', agencyCode)
    } else {
      sessionStorage.clear()
    }

    dispatch(updateAgencyCodeAction(agencyCode))
  }

  return {
    ...authState,
    login,
    logout,
    smsAuthentication,
    changeInitialPassword,
    successfullyVerified,
    updateAgencyCode,
  }
}
