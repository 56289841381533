import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit'

import {
  actionLogUsersSlice,
  ActionLogUsersState,
} from './slices/actionLogUsers'
import { agenciesSlice, AgenciesState } from './slices/agencies'
import { authSlice, AuthState } from './slices/auth'
import {
  contractStatusesSlice,
  ContractStatusesState,
} from './slices/contractStatuses'
import { loadingSlice, LoadingState } from './slices/loading'
import { manualSlice, ManualState } from './slices/manual'
import { oliveUsersSlice, OliveUsersState } from './slices/oliveUsers'
import { recruitersSlice, RecruitersState } from './slices/recruiters'
import {
  searchConditionsSlice,
  SearchConditionsState,
} from './slices/searchConditions'

const appReducer = combineReducers({
  auth: authSlice.reducer,
  loading: loadingSlice.reducer,
  oliveUsers: oliveUsersSlice.reducer,
  recruiters: recruitersSlice.reducer,
  agencies: agenciesSlice.reducer,
  contractStatuses: contractStatusesSlice.reducer,
  searchConditions: searchConditionsSlice.reducer,
  actionLogUsers: actionLogUsersSlice.reducer,
  manual: manualSlice.reducer,
})

const rootReducer = (
  state:
    | CombinedState<{
        auth: AuthState
        loading: LoadingState
        oliveUsers: OliveUsersState
        recruiters: RecruitersState
        agencies: AgenciesState
        contractStatuses: ContractStatusesState
        searchConditions: SearchConditionsState
        actionLogUsers: ActionLogUsersState
        manual: ManualState
      }>
    | undefined,
  action: AnyAction
) => {
  if (action.type === 'auth/logoutAction') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
